let workPlace = window.location.hostname;
let baseUrlWithoutProxy = '';
let googleMapsKey = '';
let customURL = '';
let googlePlacesKey = '';
let proxy = '';
/*
    @ change the name of the hotel for each hotel app project name 
    @ list of hotels
        - MeliaCasteloBranco
        - MeliaBraga
        - MeliaRia
        - MeliaSetubal
        - MeliaMaputoSky
        - MeliaMadeiraMare
*/

// Main Build Hotel Name 
// MeliaCasteloBranco | MeliaBraga | MeliaRia | MeliaSetubal | MeliaMaputoSky | MeliaMadeiraMare | MeliaAeroporto
const hotel = "MeliaBraga";

if (hotel === "MeliaCasteloBranco") {
    baseUrlWithoutProxy = "https://hoti.dev2.shareit.dev/";
    document.title = "MELIÃ CASTELO BRANCO";
    googleMapsKey = 'AIzaSyDMBFZPD46vKtg9NYt-KD743hwoht0DEao';
    googlePlacesKey = 'AIzaSyBZOrE7KwbKENLC8btDX4R4ayzQVZlCwCk';
    customURL = "melia_castelo_branco"
    
} else if (hotel === "MeliaBraga") {
    baseUrlWithoutProxy = "https://www.meliabraga.com/";
    document.title = "MELIÃ BRAGA";
    googleMapsKey = 'AIzaSyDMBFZPD46vKtg9NYt-KD743hwoht0DEao';
    googlePlacesKey = 'AIzaSyBZOrE7KwbKENLC8btDX4R4ayzQVZlCwCk';
    customURL = "melia_braga"
    
} else if (hotel === "MeliaRia") {
    baseUrlWithoutProxy = "https://hoti.dev2.shareit.dev/";
    document.title = "MELIÃ RIA AVEIRO";
    googleMapsKey = 'AIzaSyDMBFZPD46vKtg9NYt-KD743hwoht0DEao';
    googlePlacesKey = 'AIzaSyBZOrE7KwbKENLC8btDX4R4ayzQVZlCwCk';
    customURL = "melia_ria"
    
} else if (hotel === "MeliaSetubal") {
    baseUrlWithoutProxy = "https://hoti.dev2.shareit.dev/";
    document.title = "MELIÃ SETÚBAL";
    googleMapsKey = 'AIzaSyDMBFZPD46vKtg9NYt-KD743hwoht0DEao';
    googlePlacesKey = 'AIzaSyBZOrE7KwbKENLC8btDX4R4ayzQVZlCwCk';
    customURL = "melia_setubal"
    
} else if (hotel === "MeliaMaputoSky") {
    baseUrlWithoutProxy = "https://hoti.dev2.shareit.dev/";
    document.title = "MELIÃ MAPUTO SKY";
    googleMapsKey = 'AIzaSyDMBFZPD46vKtg9NYt-KD743hwoht0DEao';
    googlePlacesKey = 'AIzaSyBZOrE7KwbKENLC8btDX4R4ayzQVZlCwCk';
    customURL = "melia_maputo"
    
} else if (hotel === "MeliaMadeiraMare") {
    baseUrlWithoutProxy = "https://hoti.dev2.shareit.dev/";
    document.title = "MELIÃ MADEIRA MARE";
    googleMapsKey = 'AIzaSyDMBFZPD46vKtg9NYt-KD743hwoht0DEao';
    googlePlacesKey = 'AIzaSyBZOrE7KwbKENLC8btDX4R4ayzQVZlCwCk';
    customURL = "melia_madeira"
}
else if (hotel === "MeliaOriente") {
    baseUrlWithoutProxy = "https://www.melialisboaoriente.com/";
    document.title = "MELIÃ ORIENTE";
    googleMapsKey = 'AIzaSyCR2K9O03n59WRnHFygBB79gTonmPwKKlE';
    googlePlacesKey = 'AIzaSyBZOrE7KwbKENLC8btDX4R4ayzQVZlCwCk';
    customURL = "melia_oriente"
}
else if (hotel === "MeliaAeroporto") {
    baseUrlWithoutProxy = "https://www.melialisboaaeroporto.com/";
    document.title = "MELIÃ LISBOA AEROPORTO";
    googleMapsKey = 'AIzaSyDMBFZPD46vKtg9NYt-KD743hwoht0DEao';
    googlePlacesKey = 'AIzaSyBZOrE7KwbKENLC8btDX4R4ayzQVZlCwCk';
    customURL = "melia_aeroporto"
}

if (workPlace.includes('localhost') || workPlace.includes('stg') ) {
    googleMapsKey = 'AIzaSyDiAqr22VWYQZvCR6UADO7zJsaFGumv2hY';
    googlePlacesKey = 'AIzaSyDiAqr22VWYQZvCR6UADO7zJsaFGumv2hY';
} 

const baseUrl = proxy + baseUrlWithoutProxy;
const prefix = 'cc_';
const apiPath = "wp-json/app/";
const apiNewPath = "hoti/";
const apiFunction = "?f=";
const apiHotel = "&h=";
const apiLang = "&lang=";
const apiID = "&id=";
const apiG_ID = "&g_id=";

const Global = {
    proxy: proxy,
    websiteBaseUrlWithoutProxy: baseUrlWithoutProxy,
    websiteBaseUrl: baseUrl,
    apiPath: apiPath,
    customURL: customURL,
    apiNewPath: apiNewPath,
    apiFunction: apiFunction,
    apiHotel: apiHotel,
    apiLang: apiLang,
    apiID: apiID,
    apiG_ID: apiG_ID,
    websiteUrl: baseUrl,
    googleMapsRouteUrl: 'https://www.google.com/maps/dir',
    googleMapsAPIKey: googleMapsKey,
    googlePlacesAPIKey: googlePlacesKey,
    googleMapsKey: 'https://maps.googleapis.com/maps/api/js?key=' + googleMapsKey + '&v=3.exp&libraries=geometry,drawing,places',
    googleMapsWithCoordinates: 'https://www.google.com/maps/place/',
    localStorage: {
        generalSettings: prefix + 'generalSettings',
        googleDataSettings: 'googleDataSettings'
    }
};

export default Global;